import React, { useEffect, useState, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { connect } from "./redux/blockchain/blockchainActions";
import { fetchData } from "./redux/data/dataActions";
import * as s from "./styles/globalStyles";
import styled from "styled-components";

const truncate = (input, len) =>
  input.length > len ? `${input.substring(0, len)}...` : input;

export const StyledButton = styled.button`
  padding: 10px;
  
  border: 1px solid var(--buttborder);
  background-color: var(--buttonbg);
  
  font-weight: bold;
  color: var(--connect-text);
  width: 100px;
  cursor: pointer;
  box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 6px 0px -2px rgba(0, 0, 0, 1);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const StyledRoundButton = styled.button`
  padding: 10px;
  
  border: 1px solid var(--buttborder);
  background-color: var(--primary);
  padding: 10px;
  font-weight: bold;
  font-size: 15px;
  color: var(--plusminus-text);
  width: 30px;
  height: 30px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  box-shadow: 0px 4px 0px -2px rgba(0, 0, 0, 1);
  -webkit-box-shadow: 0px 4px 0px -2px rgba(0, 0, 0, 1);
  -moz-box-shadow: 0px 4px 0px -2px rgba(0, 0, 0, 1);
  :active {
    box-shadow: none;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
  }
`;

export const ResponsiveWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: stretched;
  align-items: stretched;
  width: 100%;
  @media (min-width: 767px) {
    flex-direction: row;
  }
`;

export const StyledLogo = styled.img`
  width: 350px;
  @media (min-width: 767px) {
    width: 500px;
  }
  transition: width 0.5s;
  transition: height 0.5s;
`;

export const StyledImg = styled.img`
  
  width: 55px;
  @media (min-width: 900px) {
    width: 60px;
  }
  @media (min-width: 1000px) {
    width: 65px;
  }
  transition: width 0.5s;
  

  
`;

export const StyledLink = styled.a`
  color: var(--link);
  
  text-shadow: 1px 1px 2px black;
`;

export const StyledLink2 = styled.a`
  color: var(--mainlink);
  
  font-weight: bold;
  
`;
function App() {
  const dispatch = useDispatch();
  const blockchain = useSelector((state) => state.blockchain);
  const data = useSelector((state) => state.data);
  const [claimingNft, setClaimingNft] = useState(false);
  const [feedback, setFeedback] = useState(`You can mint just one NFT. Another transaction will fail but gas fees will not!`);
  const [mintAmount, setMintAmount] = useState(1);
  const [CONFIG, SET_CONFIG] = useState({
    CONTRACT_ADDRESS: "",
    SCAN_LINK: "",
    NETWORK: {
      NAME: "",
      SYMBOL: "",
      ID: 0,
    },
    NFT_NAME: "",
    SYMBOL: "",
    MAX_SUPPLY: 1,
    WEI_COST: 0,
    DISPLAY_COST: 0,
    GAS_LIMIT: 0,
    MARKETPLACE: "",
    MARKETPLACE_LINK: "",
    SHOW_BACKGROUND: false,
  });

  const claimNFTs = () => {
    let cost = CONFIG.WEI_COST;
    let gasLimit = CONFIG.GAS_LIMIT;
    let totalCostWei = String(cost * mintAmount);
    let totalGasLimit = String(gasLimit * mintAmount);
    console.log("Cost: ", totalCostWei);
    console.log("Gas limit: ", totalGasLimit);
    setFeedback(`Minting your ${CONFIG.NFT_NAME}...`);
    setClaimingNft(true);
    blockchain.smartContract.methods
      .mint(mintAmount)
      .send({
        gasLimit: String(totalGasLimit),
        to: CONFIG.CONTRACT_ADDRESS,
        from: blockchain.account,
        value: totalCostWei,
      })
      .once("error", (err) => {
        console.log(err);
        setFeedback("Sorry, something went wrong please try again later.");
        setClaimingNft(false);
      })
      .then((receipt) => {
        console.log(receipt);
        setFeedback(
          `${CONFIG.NFT_NAME} succesfully minted! Go visit Opensea.io to view it.`
        );
        setClaimingNft(false);
        dispatch(fetchData(blockchain.account));
      });
  };

  const decrementMintAmount = () => {
    let newMintAmount = mintAmount - 1;
    if (newMintAmount < 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const incrementMintAmount = () => {
    let newMintAmount = mintAmount + 0;
    if (newMintAmount > 1) {
      newMintAmount = 1;
    }
    setMintAmount(newMintAmount);
  };

  const getData = () => {
    if (blockchain.account !== "" && blockchain.smartContract !== null) {
      dispatch(fetchData(blockchain.account));
    }
  };

  const getConfig = async () => {
    const configResponse = await fetch("/config/config.json", {
      headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
      },
    });
    const config = await configResponse.json();
    SET_CONFIG(config);
  };

  useEffect(() => {
    getConfig();
  }, []);

  useEffect(() => {
    getData();
  }, [blockchain.account]);

  return (
    <s.Screen>
      <s.Container
        flex={1}
        ai={"center"}
        style={{ padding: 24, backgroundColor: "var(--primary)" }}
        image={CONFIG.SHOW_BACKGROUND ? "/config/images/bg.png" : null}
      >
         <s.SpacerSmall />
        <StyledLogo alt={"logo"} src={"/config/images/logo.png"} />
        <s.SpacerLarge />
        
        
 
       

        <s.Container 

flex={2}
jc={"center"}
ai={"center"}
style={{

  maxWidth:"70%",
  maxHeight:"60px",
  

  margin:"auto",
  backgroundColor: "var(--upaccent)",
  padding: 14,
 
  border: "2px solid var(--secondary)",
  //boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
}}
>
<s.TextSupply
              style={{
                textAlign: "center",
                fontSize: 40,
                fontWeight: "none",
                color: "var(--title)",
                //textShadow: "1px 1px 2px black"
                
                
                
                
              }}
            >
              
              <font color="#00ffb0">~</font><font color="#00000">~</font>elements
            </s.TextSupply>
        
          
        </s.Container>
          
          
          
         
          
          <s.Container 
         
            flex={2}
            jc={"center"}
            ai={"center"}
            style={{

              maxWidth:"70%",
              
            
              margin:"auto",
              backgroundColor: "var(--accent)",
              padding: 40,
              
              border: "2px solid var(--secondary)",
              //boxShadow: "0px 5px 11px 2px rgba(0,0,0,0.7)",
            }}
          >
             <s.TextDescription
            style={{
              textAlign: "center",
              color: "var(--primary-text)",
              
              
              
              
            
            }}
          >
        
            
           WELCOME TO 5ML ELEMENTS GEN.01 FREEMINT! 
          
            <s.SpacerXSmall />
            Please join us on <StyledLink target={"_blank"} href="https://discord.gg/CGjJtY678d"> Discord</StyledLink> and follow us on <StyledLink target={"_blank"} href="https://www.twitter.com/5metalab">Twitter</StyledLink> or <StyledLink target={"_blank"} href="https://www.instagram.com/5metalab">Instagram</StyledLink>.
            <s.SpacerXSmall />
            Check our website homepage at <StyledLink target={"_blank"} href="https://www.5metalab.com"> 5metalab.com</StyledLink>.
            <s.SpacerXSmall />
           Mint the element and wear your <StyledLink2 target={"_blank"} href="https://5metalab.com/#4"> WHITECOAT</StyledLink2>!

          </s.TextDescription>
          <s.SpacerSmall />
          <s.SpacerSmall />

   <s.Container 
            flex={2}
            
            jc={"center"}
            ai={"center"}
            style={{

              maxWidth:"100%",
              
              objectFit: "scale-down",
              
            
              margin:"auto",
              backgroundColor: "var(--midaccent)",
              padding: 5,

            }}
            >
           
            
            
           <s.TextSupply
            style={{
              textAlign: "center",
              fontSize: 40,
              fontWeight: "none",
              color: "var(--accent-text)",
              //textShadow: "1px 1px 2px black",
              
              
              
              
              
            }}
          >
            
            SOLD OUT, YOU CAN FIND IT ON <StyledLink2 target={"_blank"} href="https://opensea.io/collection/elements-of-5ml-gen-01"> OPENSEA</StyledLink2>
          </s.TextSupply>
          
          
         
        </s.Container>
        
        <s.SpacerSmall />
        <s.SpacerSmall />
             
              
               <s.TextTitle
                  style={{ textAlign: "center", color: "var(--price-text)", textShadow: "1px 1px 2px black",}}
                >
                  FREEMINT
                </s.TextTitle>
                <s.SpacerXSmall />
                <s.TextDescription
                  style={{ textAlign: "center", color: "var(--disclaimer-text)" }}
                >
                
                </s.TextDescription>
                
                
                  
                
                    
              
         
          </s.Container>
          
       

         
                
                
                <s.SpacerLarge/>

<s.Container jc={"center"} display={"flex"} ai={"center"} style={{ width: "90%" }}>





          

          <s.TextDescription
            style={{
              textAlign: "center",
              fontSize: "10px",
              color: "var(--primary-text)",
            }}
          >
        <s.SpacerSmall />
        <a href="https://raritysniper.com/nft-drops-calendar" target="_blank"> <img src="/config/images/sniper2.png" alt="as seen on https://raritysniper.com/" width="80" /></a>
        <s.SpacerSmall />
       <a href="https://icy.tools/calendar" target="_blank"> <img src="https://cdn.icy.tools/images/icy-tools-dark.svg" alt="as seen on http://icy.tools' NFT Calendar" width="100" /></a>
       <s.SpacerSmall />
        <a href="https://nftcalendar.io/" target="_blank"> <img src="/config/images/nftcalendar2.png" alt="as seen on https://nftcalendar.io/" width="80" /></a>
        <s.SpacerSmall />
        <a href="https://nftdroops.com/" target="_blank"> <img src="/config/images/NFTdroops.png" alt="as seen on https://nftdroops.com/" width="100" /></a>
        <s.SpacerSmall />
           5metalab terms and conditions. @2023
          </s.TextDescription>
          
        </s.Container>
                    
                     

                    

       
      
        
      </s.Container>

      
      

  
    </s.Screen>
  );
}

export default App;
